<!--  -->
<template>
    <div class='page-history-summary flex-center player-history'>
        <component-bcg url="history/bcg1.png"></component-bcg>
        <div class="bcg2">
            <div class="l d1">
                <div class="head">
                    <img :src="`${PARAMS.assetsUrlV2_0}/history/b1.png`" class="h-img">
                    <span class="text-normal bold text-blue-color h-txt" v-if="isShow==0">{{"胜率"}}</span>
                    <span class="text-normal bold text-blue-color h-txt" v-if="isShow==1">{{"正确率"}}</span>
                </div>
                <span class="text-normal bold text-blue-color pie-txt">{{(count.winRate||0)+"%"}}</span>
                <div class="pie d2">
                    <div class="z1">
                    </div>
                    <pieChart :percentageInfo="count.winRate||0" :idName="1" ref="game-pie"></pieChart>
                    <!-- <div class="b2">
                        <div class="s1"></div>
                    </div> -->
                </div>
                <div class="content">
                    <div class="t">
                        <div class="item">
                            <span class="text-normal bold text-blue-color con-txt">{{"近7天"}}</span>
                            <div class="play-num">
                                <div class="num">
                                    <span class="text-normal bold text-blue-color num-txt">{{count.count07}}</span>
                                    <div class="x1"></div>
                                </div>
                                <span v-if="isShow==1" class="text-normal bold text-blue-color con-txt">{{"题"}}</span>
                                <span v-if="isShow==0" class="text-normal bold text-blue-color con-txt">{{"局"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="c">
                        <div class="item">
                            <span class="text-normal bold text-blue-color con-txt">{{"近30天"}}</span>
                            <div class="play-num">
                                <div class="num">
                                    <span class="text-normal bold text-blue-color num-txt">{{count.count30}}</span>
                                    <div class="x1"></div>
                                </div>
                                <span v-if="isShow==1" class="text-normal bold text-blue-color con-txt">{{"题"}}</span>
                                <span v-if="isShow==0" class="text-normal bold text-blue-color con-txt">{{"局"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="b">
                        <div class="item">
                            <span class="text-normal bold text-blue-color con-txt">{{"累计"}}</span>
                            <div class="play-num">
                                <div class="num">
                                    <span class="text-normal bold text-blue-color num-txt">{{count.countAll}}</span>
                                    <div class="x1"></div>
                                </div>
                                <span v-if="isShow==1" class="text-normal bold text-blue-color con-txt">{{"题"}}</span>
                                <span v-if="isShow==0" class="text-normal bold text-blue-color con-txt">{{"局"}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sum_bcg">
                <div class="sum">
                    <span class="text-normal bold sum-txt">{{"好友对战历史战绩"}}</span>
                </div>
            </div>
            <div class="r">
                <div class="head">
                    <div class="title">
                        <span class="text-normal bold text-yel-color time-row">{{"时间"}}</span>
                        <span class="text-normal bold text-yel-color black-row">{{"黑棋"}}</span>
                        <span class="text-normal bold text-yel-color white-row">{{"白棋"}}</span>
                        <span class="text-normal bold text-yel-color hand-row">{{"手数"}}</span>
                        <span class="text-normal bold text-yel-color result-row">{{"结果"}}</span>
                        <span class="empty"></span>
                    </div>
                    <div class="f1"></div>
                </div>
                <div class="items-empty" v-if="dataLength==0">
                    <span class="text-normal bold text-yel-color e">{{"当前棋谱还是空的,"}}</span>
                    <span class="text-normal bold text-yel-color e">{{"快去对弈吧~"}}</span>
                    <div class="btn scale-button" @click="goPlay()">
                        <confirmPlay>
                            <span class="text-normal blod b">{{"去对弈"}}</span>
                        </confirmPlay>
                    </div>
                </div>
                <div class="items mini-scrollbar max-height" 
                @scroll="scrolLoad"
                ref="commentContent"
                v-else 
                >
                    <div ref="commentBox">
                        <div class="d3" v-for="(v,i) in dataListCul" :key="i">
                            <div class="j1">
                                <div class="item">
                                    <span class="text-normal text-yellow-color i time-row">{{v.date}}</span>
                                    <span class="text-normal text-yellow-color i black-row">{{v.pb}}</span>
                                    <span class="text-normal text-yellow-color i white-row">{{v.pw}}</span>
                                    <span class="text-normal text-yellow-color i hand-row">{{v.totalNum}}</span>
                                    <div class="result-row">
                                        <div class="game-result-bcg" :class="v.resultStyle">
                                            <div class="game-result-img"></div>
                                        </div>
                                        <result class="text-normal text-yellow-color i" :result="v.gameResult"></result>
                                    </div>
                                    <div class="btn scale-button">
                                        <confirmPlay @click="gotoReview(v)">
                                            <span class="text-normal blod text-red-color i flex-center">{{"查看"}}</span>
                                        </confirmPlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <chatLoading ref="chatLoading" :loading.sync="loading" color="#D2B59A"></chatLoading>
                </div>
                <!-- <div class="d4 mini-scrollbar">
                    <div class="h1 scale-button"></div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import com from '@/views/history/page/summary.vue'

    export default {
        name: '',
        extends: com,
        props: {},
        data() {
            return {
                gameType: '68',//高途:6，高途人机:60，高途人人:68
            };
        },
        methods: {
            pageInit(){
                // this.playCount()
                this.dataCount()
                this.gameList()
                this.eventSet()
            },
            async dataCount(){
                let {usercode: user_code, gameType} = this
                let res = await this.api.dataCount({user_code, game_type: gameType})
                if(res) {
                    let {userGameCount,userGameDay07Count,userGameDay30Count,winRate} = res.countAll
                    let info = {
                        count07:userGameDay07Count,
                        count30:userGameDay30Count,
                        countAll:userGameCount,
                        winRate:Math.round(winRate*100),
                    }
                    this.count = info
                    this.countA = info
                }
            },
            getResultStyle(result){
                result = this._common.deepCopy(result)
                let re = result.gameResult || ''
                if(re == 'N+R' || re == 'R+R') return 'draw'
                let {app: {nickname} = {}} = this.$store.state
                let user = ''
                if(nickname == result.pb) user = 'b' 
                if(nickname == result.pw) user = 'w' 
                let data = this._common.parseResultSelect(re)
                let win = this._common.resultWinLabel(re)
                data.win = win
                return data.win == user ? 'win' : 'lose'
            },
            async gameList(){
                let {usercode: user_code,page,size,gameType} = this
                let opt = {user_code,page,size,gameType}
                let res = await this.api.gameList(opt)
                if(res) {
                    let {gameMetas} = res
                    this.dataList = this.dataHandle(gameMetas)
                }
            },
            async scrolLoad(){
                let {loading,usercode: user_code,page,size,dataLength:length,listCount:total,gameType} = this
                if(loading == true) return
                if(!this.$refs['commentContent']) return
                let index = this.$refs['commentContent'].scrollTop
                let contentHeight = this.$refs['commentContent'].offsetHeight
                let boxHeight = this.$refs['commentBox'].offsetHeight
                if((boxHeight - index + 30) > contentHeight) return
                this.loading = true
                if(length==total) {
                    this.loading = false
                    return
                }
                page++
                let res = await this.api.gameList({user_code,page,size,gameType,noLoad: true})
                if(res){
                    let {gameMetas,count} = res
                    this.listCount = count
                    if(gameMetas.length){
                        this.page = page
                        let r = this.dataHandle(gameMetas)
                        this.dataList = this.dataList.concat(r)
                    }
                }
                this.loading = false
            },
        },
        created() {},
        components: {},
        computed: {
            dataListCul(){
                let {dataList: list} = this
                for (const i in list) {
                    list[i].resultStyle = this.getResultStyle(list[i]) 
                }
                return this.dataList
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    .page-history-summary.player-history{
        .bcg2{
            .background-img-max(url('@{assetsUrlMind}/img/history/bcg2.png')) !important;
            .sum_bcg{
                .background-img-max(url('@{assetsUrlMind}/img/history/sum_bcg.png'));
                width: 112px;
                height: 40px;
                position: absolute;
                bottom: 27px;
                left: 75px;
                .sum{
                    .background-img-max(url('@{assetsUrlMind}/img/history/sum.png'));
                    position: absolute;
                    top: 3px;
                    left: 4px;
                    width: 104px;
                    height: 31px;
                }
                .sum-txt{
                    color: #A9693D;
                    font-size: 10px;
                    transform: scale(0.9);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            .r{
                .items{
                    padding: 5px 0;
                    .d3{
                        .j1{
                            .item{
                                .result-row{
                                    .game-result-bcg{
                                        position: absolute;
                                        .game-result-img{
                                            position: relative;
                                            z-index: 1;
                                        }
                                    }
                                    .game-result-bcg.draw{
                                        .background-img-max(url('@{assetsUrlMind}/img/history/draw_bcg.png'));
                                        top: -14px;
                                        left: 5px;
                                        width: 32px;
                                        height: 28px;
                                        .game-result-img{
                                            .background-img-max(url('@{assetsUrlMind}/img/history/draw.png'));
                                            width: 28px;
                                            height: 24px;
                                        }
                                    }
                                    .game-result-bcg.win{
                                        .background-img-max(url('@{assetsUrlMind}/img/history/win_bcg.png'));
                                        top: -14px;
                                        left: 4px;
                                        width: 30px;
                                        height: 26px;
                                        .game-result-img{
                                            .background-img-max(url('@{assetsUrlMind}/img/history/win.png'));
                                            width: 26px;
                                            height: 22px;
                                        }
                                    }
                                    .game-result-bcg.lose{
                                        .background-img-max(url('@{assetsUrlMind}/img/history/lose_bcg.png'));
                                        top: -14px;
                                        left: 8px;
                                        width: 25px;
                                        height: 27px;
                                        .game-result-img{
                                            .background-img-max(url('@{assetsUrlMind}/img/history/lose.png'));
                                            width: 22px;
                                            height: 24px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>